import { Component, AfterViewInit, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ROUTES } from './menu-items';
import { RouteInfo } from './sidebar.metadata';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  showMenu = '';
  showSubMenu = '';
  public sidebarnavItems: any[];
  // this is for the open close
  addExpandClass(element: any) {
    if (localStorage.getItem('role') == '0') {
      if (element === this.showMenu) {
        this.showMenu = '0';
      } else {
        this.showMenu = element;
      }
    }
  }
  addActiveClass(element: any) {
    if (localStorage.getItem('role') == '0') {
      if (element === this.showSubMenu) {
        this.showSubMenu = '0';
      } else {
        this.showSubMenu = element;
      }
    }

  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute
  ) { }
  // End open close
  ngOnInit() {
    if (localStorage.getItem('role') == '0')
      this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
  }
  logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('role');
    this.router.navigateByUrl('/')
  }
}

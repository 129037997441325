import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard/tag_contact',
    title: 'Accueil',
    icon: 'mdi mdi-home',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/dashboard/contacts',
    title: 'Contacts',
    icon: 'mdi mdi-account-multiple',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/dashboard/affaires',
    title: 'Affaires',
    icon: 'mdi mdi-briefcase',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/dashboard/rubriques',
    title: 'Rubriques',
    icon: 'mdi mdi-format-list-bulleted',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/dashboard/tags',
    title: 'Évènements',
    icon: 'mdi mdi-tag',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/dashboard/utilisateurs',
    title: 'Utilisateurs',
    icon: 'mdi mdi-account-multiple',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  }
];

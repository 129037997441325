import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Md5 } from 'ts-md5/dist/md5';
import { BaseUrl } from './../entities/base_url';
import { User } from './../entities/user';
import swal from 'sweetalert';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @Input() email: string = "";
  @Input() password: string = "";
  @Input() emailhelp: string = "";
  msg: string = "";
  message: string = "";
  success: string = "";
  userdata: any;
  hidden: boolean = true;
  constructor(private http: HttpClient, private router: Router) { }
  results: any = [];
  base_url: BaseUrl = new BaseUrl();
  dataofuser: User = new User();
  userrinit: any;


  ngOnInit() {
    if (localStorage.getItem('currentUser')) {
      // logged in so return true
      localStorage.removeItem('currentUser');
      localStorage.removeItem('role');
    }
  }
  submit() {
    if (this.email.length > 0 && this.password.length > 0) {
      const md5 = new Md5();
      let data = {
        "email": this.email,
        "password": md5.appendStr(this.password).end()
      }
      this.http.post(this.base_url.name + '/signup', data).subscribe(res => {

        this.results = res;
       
        if (this.results.status && this.results.user.length > 0) {
          localStorage.setItem('currentUser', this.results.user);
          localStorage.setItem('role', this.results.user[0].role);
          if (this.results.user[0].role == "0") {
            this.router.navigateByUrl('/dashboard/tag_contact');
          } else {
            this.router.navigateByUrl('/contacts/listes');
          }
        } else {
          this.msg = 'Email ou mot de passe non valide';
        }
      });
    }
    else { this.msg = "Merci de remplir les champs indiqués!"; }
  }

  mdpsection() {
    this.hidden = false;
  }

  reinitial() {
    if (this.emailhelp.length > 0) {
      this.http.get(this.base_url.name + '/rein/' + this.emailhelp).subscribe(res => {
        this.userrinit = res;
        this.dataofuser = <User>res;
        if (this.userrinit.length > 0) {
          this.dataofuser[0].password = this.success = this.makeid(8);
          this.http.post(this.base_url.name + '/sendemail', this.dataofuser).subscribe(res => {

            swal({
              title: "Votre mot de passe a été envoyé avec succés.",
              text: "Merci de vérifier votre boite de réception!",
              icon: "success",

            })
              .then(() => {
                location.reload();
              });
          });
          const md5 = new Md5();
          this.dataofuser[0].password = md5.appendStr(this.success).end();

          this.http.put(this.base_url.name + '/login/' + this.dataofuser[0]._id, this.dataofuser[0]).subscribe(res => { this.ngOnInit(); });

        } else {
          this.message = "Email non valide";
        }
      });
    }
  }

  makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
